<template>
  <div class="d-flex align-items-center justify-content-center h-100 flex-column" style="font-family: Montserrat !important">
    <div class="row">
      <div class="col-12 col-lg-6" style=" padding: 92px 20px 0px 20px !important;">
 <div class="row">
      <div class="col-12 text-center text-md-left">
        <div class="page-heading mb-3" :class="[isCreate?'text-center':'']">
          {{ translateCustomText("TITLE AND DESCRIPTION") }}
        </div>
        <div v-if="!isCreate" class="page-description">
          {{
            translateCustomText("Edit your title and description below, then click the 'Save' button")
          }}
        </div>
         <div v-else class="page-description text-center">
          {{
            translateCustomText("Choose your title and description below, then click the 'Save' button")
          }}
        </div>
      </div>
      <div class="col-12 mt-5 text-left">
        <label
          for="title"
          class="control-label d-flex justify-content-between"
         
        >
          <span>{{ translateCustomText("SHOP TITLE") }}</span>
          <i
            v-show="error.title"
            style="color: red; font-size: 14px; font-weight: 500"
            > {{ translateCustomText('*required') }}</i
          ></label
        >
        <div class="">
          <input
            type="text"
            v-model.trim="updateTitle"
            name=""
            class="control-input px-3 py-2"
            id="title"
            :placeholder="translateCustomText('Enter your shop title here')"
          />
        </div>
      </div>
      <div class="col-12 mt-5 text-left">
        <label
          for="description"
          style="max-width: 904px"
          class="control-label d-flex justify-content-between"
          ><span> {{ translateCustomText("SHOP DESCRIPTION") }}</span>
          <i
            v-show="error.desc"
            style="color: red; font-size: 14px; font-weight: 500"
            >{{ translateCustomText('*required') }}</i
          ></label
        >
        <div class="">
          <textarea
            name=""
            :value="getSanitizedDesc"
            @input="sanitizeInput"
            id="description"
            cols="30"
            rows="10"
            class="control-input px-3 py-2"
            style="max-width: 904px; max-height: 125px"
            :placeholder="
              translateCustomText(
                `Enter your shop description here (maximum 400 characters)`
              )
            "
          ></textarea>
        </div>
        <div
          class="text-right"
          style="max-width: 904px; color: #503a3c; text-align: right"
        >
          {{ getDescriptionValue.length }}/400
        </div>
      </div>
      <div class="col-12 mt-5 text-left" v-if="!isAddNew">
        <div class="d-flex justify-content-between">
          <ElthButton
            @click="backPage()"
            variant="boardered"
            :disabled="nextBtnSpinner"
          >
            {{ translateCustomText("Back") }}
          </ElthButton>
          <ElthButton
            @click="nextPage()"
            :isLoading="nextBtnSpinner"
            :disabled="nextBtnSpinner"
          >
            {{ translateCustomText("Save") }}
          </ElthButton>
          <button
            style="visibility: hidden"
            class="btn elt-btn d-none d-md-block"
          ></button>
        </div>
      </div>
      <div class="col-12 mt-5 text-left" v-else>
        <div class="d-flex justify-content-center">
          <ElthButton
            @click="addNextPage()"
            :isLoading="nextBtnSpinner"
            :disabled="nextBtnSpinner"
          >
            {{ translateCustomText("Save") }}
          </ElthButton>
        </div>
      </div>
    </div>
      </div>
      <div class="col-12 col-md-6 pr-0  d-none d-lg-block">
        <lazy-image style="width:100%; height:100vh;" :src="translateCustomText('image')"></lazy-image>

      </div>
    </div>
   
  </div>
</template>

<script>
import axios from "axios";
import cssFunctionMixin from "../../../customize/mixin/cssFunction.js";
import TranslationMixin from "../../../customize/mixin/TranslationMixin";
import ElthButton from "./ElthButton.vue";
import { mapGetters } from "vuex";
export default {
  mixins: [cssFunctionMixin, TranslationMixin],
  props: [
    "baldenTitle",
    "baldenDesc",
    "isDesktopView",
    "image",
    "apiToken",
    "pageHeight",
    "quizId",
    "isAddNew",
    "isCreate"
  ],
  components: {
    ElthButton,
  },
  mounted() {},
  data() {
    return {
      error: {
        title: false,
        desc: false,
      },
      nextBtnSpinner: false,
      showAlert: false,
      alertMsg: "",
      updateTitle: this.baldenTitle ? this.baldenTitle.slice() : "",
      updateDesc: this.baldenDesc ? this.baldenDesc.slice() : "",
      // Step 1 PageDesign Data
      pageDesign: {},
    };
  },

  computed: {
    ...mapGetters(["getEnvConst"]),
    getEnv() {
      // getEnvConst
      return this.getEnvConst;
    },

    getTitleValue() {
      return this.updateTitle;
    },
    getDescriptionValue() {
      return this.updateDesc;
    },
    getSanitizedDesc(){
      return this.updateDesc.replace(/<\/?[^>]+(>|$)/g, "");
    }
  },
  watch: {
    getTitleValue(newVal, oldVal) {
      if (newVal.length > 30) {
        this.updateTitle = oldVal;
      }
    },
    getDescriptionValue(newVal, oldVal) {
      if (newVal.length > 400) {
        this.updateDesc = oldVal;
      }
    },
  },
  methods: {
    sanitizeInput(event) {
      const text = event.target.value;
      const sanitizedText = text.replace(/<\/?[^>]+(>|$)/g, "");
      this.updateDesc = sanitizedText;
    },

    addNextPage() {
      if (this.checkValidation()) {
        this.$emit("updateTextDesc", {
          title: this.updateTitle,
          desc: this.updateDesc,
        });
      }
    },
    translateCustomText(key) {
      return this.translateToItalian(key, this.quizId);
    },
    updateDescription(e) {
      this.updateDesc = e;
    },
    checkValidation() {
      if (this.updateTitle.trim() == "") {
        this.error.title = true;
      } else {
        this.error.title = false;
      }
      if (this.updateDesc.trim() == "") {
        this.error.desc = true;
      } else {
        this.error.desc = false;
      }
      return this.updateDesc.trim() != "" && this.updateTitle.trim() != "";
    },

    backPage() {
      this.$emit("moveTo", 1);
    },
    async nextPage() {
      this.nextBtnSpinner = true;

      if (!this.checkValidation()) {
        this.nextBtnSpinner = false;
        return;
      }
      try {
        let data = {
          title: this.updateTitle,
          description: this.updateDesc,
          email:
            localStorage.getItem("already_record_email") ||
            this.$route.query.email,
          api_token: this.apiToken,
        };

        if (this.image) data["image"] = this.image;
        const response = await axios.post(
          `/api/custom/collection/update/title`,
          data
        );

        if (response.status == 200) {
          this.$toasted.show(
            this.translateCustomText(`Updated Title and Description.`),
            {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            }
          );
          this.$emit("updateTextDesc", {
            title: this.updateTitle,
            desc: this.updateDesc,
          });
          this.$emit("moveTo", 1);
        } else {
          this.$toasted.show(
            this.translateCustomText(`An error has occurred`),
            {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            }
          );
        }
      } catch (error) {
        if (error) {
          this.$toasted.show(
            this.translateCustomText(`An error has occurred`),
            {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            }
          );
        }
        throw error;
      } finally {
        this.nextBtnSpinner = false;
      }
    },
  },
};
</script>

<style scoped>
.control-label {
  color: #503a3c;

  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 90.909% */
}
.page-heading {
  color: #503a3c;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 100% */
  text-transform: uppercase;
}
.page-description {
  color: #503a3c;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 100% */
}
@media (max-width: 767px) {
 
  .topSection .title{
    font-size: 22px;
    text-align: center;
  }
  .topSection .description{
    text-align: center;
  }
}

.control-input {
  border-radius: 15px;
  border: 1px solid #503a3c;
  /* max-width: 604px; */
  width: 100%;
  background: #fff;
}

.container-q {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 1200px) {
  .container-q,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 985px;
  }
}
</style>
