import { render, staticRenderFns } from "./ElthButton.vue?vue&type=template&id=57a3d4a5&scoped=true&"
import script from "./ElthButton.vue?vue&type=script&lang=js&"
export * from "./ElthButton.vue?vue&type=script&lang=js&"
import style0 from "./ElthButton.vue?vue&type=style&index=0&id=57a3d4a5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57a3d4a5",
  null
  
)

export default component.exports